import { Api } from '@/api/api'
import { ProductBrand } from '@/models/www/ProductBrand'

const baseURL = process.env.ACADEMIC_API_ENDPOINT

async function getBrands(): Promise<ProductBrand[]> {
  return Api.get<ProductBrand[]>(`${baseURL}/v0/products/brands/all`)
}

async function getBrandById(id: string): Promise<ProductBrand | null> {
  return Api.get<ProductBrand | null>(`${baseURL}/v0/products/brands/${id}`)
}

async function deleteBrand(brand: ProductBrand): Promise<void> {
  if (!brand._id) return
  await Api.del<ProductBrand | null>(
    `${baseURL}/v0/products/brands/${brand._id}`
  )
}

async function saveBrand(productBrand: ProductBrand): Promise<ProductBrand> {
  if (!productBrand._id) delete productBrand._id

  if (productBrand._id) {
    return Api.put<ProductBrand>(
      `${baseURL}/v0/products/brands/${productBrand._id}`,
      productBrand
    )
  }

  return Api.post<ProductBrand>(`${baseURL}/v0/products/brands`, productBrand)
}

export { getBrands, getBrandById, deleteBrand, saveBrand }


import { getBrands, getBrandById, deleteBrand, saveBrand } from '@/api/product-brands.api'
// import { ToastLife, ToastSeverities } from '../../models/components/toast/enums'
import { ProductBrand } from '@/models/www/ProductBrand'
import { getProducts } from '@/api/products.api'
import { getCategories } from '@/api/product-categories.api'
import { FilterMatchMode } from 'primevue/api'
import EditBrandDialog from '@/components/www/EditBrandDialog.vue'
import DataTable from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import { defineComponent, onMounted, Ref, ref } from 'vue'
import { ToastLife } from '@/models/components/toast/enums'
import { useConfirm } from 'primevue/useconfirm'
import { Country } from '@/models/countries/classes/Country'
import { CountryAPI } from '@/api/countries.api'
import { Product } from '@/models/www/Products'
import { ProductCategory } from '@/models/www/ProductCategory'

const emptyProductBrand: () => ProductBrand = () => ({
  name: '',
  logoUrl: '',
  certifiesProducts: [],
  cobrandsProducts: [],
  categories: [],
  hiddenOnCountries: [],
  url: '',
  description: '',
  _id: ''
})

export default defineComponent({
  name: 'ProductBrandsListView',
  components: {
    EditBrandDialog,
    DataTable
  },
  setup () {
    const toast = useToast()
    const loading = ref<boolean>(false)
    const expandedRows = ref<Array<ProductBrand>>([])
    const brands = ref<Array<ProductBrand>>([])
    const products: Ref<Product[]> = ref([])
    const categories: Ref<ProductCategory[]> = ref([])
    const countries = ref<Country[]>([])
    const showEditDialog = ref<boolean>(false)
    const editingBrand = ref<ProductBrand>(emptyProductBrand())
    const showPreview = ref<boolean>(false)
    const confirm = useConfirm()

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    async function updateBrandList (): Promise<void> {
      loading.value = true
      brands.value = await getBrands()
      loading.value = false
    }

    const updateProducts = async () => {
      loading.value = true
      // ---------------------
      const { remotes, careers } = await getProducts({ all: true })
      products.value = [...remotes, ...careers]
      // ---------------------
      loading.value = false
    }

    const updateCategories = async () => {
      loading.value = true
      // ---------------------
      categories.value = await getCategories()
      // ---------------------
      loading.value = false
    }

    const addNew = async () => {
      editingBrand.value = emptyProductBrand()

      showEditDialog.value = true
    }

    const onEdit = async (brand: ProductBrand) => {
      console.log(brand)
      if (!brand._id) return
      const fetchedBrand = await getBrandById(brand._id)
      if (fetchedBrand) {
        editingBrand.value = fetchedBrand
        showEditDialog.value = true
      }
    }

    const confirmBrandDeletion = (brand: ProductBrand) => new Promise<boolean>((resolve) => confirm.require({
      group: 'global',
      message: `¿Estás seguro de eliminar la empresa ${brand.name}?`,
      header: '¡Alto ahí!',
      icon: 'pi pi-exclamation-triangle',
      accept: () => resolve(true),
      reject: () => resolve(false)
    }))

    const onDelete = async (brand: ProductBrand) => {
      try {
        if (!await confirmBrandDeletion(brand)) return

        await deleteBrand(brand)

        toast.add({
          severity: 'success',
          summary: 'Empresa eliminada',
          detail: `La empresa ${brand.name} ha sido eliminada`,
          life: ToastLife.Default
        })

        await updateDeps()
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'Error al eliminar la empresa',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
    }

    const onSaveBrand = async (brand: ProductBrand) => {
      loading.value = true
      try {
        await saveBrand(brand)
        await updateDeps()
        toast.add({
          severity: 'success',
          summary: 'Empresa guardada',
          detail: `La empresa ${brand.name} ha sido guardada`,
          life: ToastLife.Default
        })
        if (!brand._id) {
          showEditDialog.value = false
        }
      } catch (error) {
        console.error(error)
        toast.add({
          severity: 'error',
          summary: 'Error al guardar la empresa',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
      loading.value = false
    }

    const onCancelEditDialog = async () => {
      showEditDialog.value = false
    }

    const getCountryNameById = (countryId: string) => countries.value.find((country) => country.id === countryId)?.name
    const getProductNameById = (productId: string) => products.value.find((product) => product._id === productId)?.title
    const getCategoryNameById = (categoryKey: string) => categories.value.find((category) => category.key === categoryKey)?.name

    const updateDeps = async () => {
      await updateBrandList()
      await updateProducts()
      await updateCategories()
    }

    const setLoadingValue = (value: boolean) => { loading.value = value }

    onMounted(async () => {
      countries.value = await CountryAPI.findAll()
      await updateDeps()
    })

    return {
      addNew,
      brands,
      products,
      categories,
      getCountryNameById,
      getProductNameById,
      getCategoryNameById,
      editingBrand,
      expandedRows,
      filters,
      loading,
      onCancelEditDialog,
      onEdit,
      onDelete,
      onSaveBrand,
      showEditDialog,
      setLoadingValue,
      showPreview
      // switchPreview
    }
  }
})


import CountrySelector from '@/components/www/CountrySelector.vue'
import { ProductBrand } from '@/models/www/ProductBrand'
import { uploadSvg } from '@/api/www.api'
import Dialog from 'primevue/dialog'
import MultiSelect from 'primevue/multiselect'
import { computed, watch, defineComponent, PropType, reactive } from 'vue'
import FileUpload from 'primevue/fileupload'
import { Product } from '@/models/www/Products'
import { ProductCategory } from '@/models/www/ProductCategory'
import { useToast } from 'primevue/usetoast'
import { ToastLife } from '@/models/components/toast/enums'

export default defineComponent({
  name: 'EditBrandDialog',
  components: { CountrySelector, Dialog, MultiSelect, FileUpload },
  emits: [
    'onCancel',
    'onSave',
    'loading'
  ],
  props: {
    brand: {
      type: Object as PropType<ProductBrand>,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    products: {
      type: Object as PropType<Product[]>,
      required: true
    },
    categories: {
      type: Object as PropType<ProductCategory[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const toast = useToast()
    const editableBrand = reactive(props.brand)

    const visible = computed({
      get () {
        return props.show
      },
      set () {
        emit('onCancel')
      }
    })

    const isValidData = computed(() => Boolean(editableBrand.name.trim() && editableBrand.logoUrl?.trim()))
    watch(editableBrand, () => { console.log(editableBrand) })

    const onSave = () => {
      emit('onSave', editableBrand)
    }

    const closeEditDialog = () => {
      emit('onCancel')
    }

    /**
     * Executes when file has uploaded satisfactorily
     * @param { XMLHttpRequest } xhr XMLHttpRequest
     * @param { unknown } files
     * @returns Void
     */
    const onUploaded = (xhr: XMLHttpRequest, files: any) => {
      console.log({ xhr, files })
    }

    /**
     * Executes to upload image.
     * @param { File[] } files
     * @returns Void
     */
    const onUpload: (event: { files: File[] }) => Promise<void> = async (event) => {
      emit('loading', true)
      try {
        const response = await uploadSvg(event.files[0])
        editableBrand.logoUrl = response.url
      } catch (error) {
        console.log(error)
        toast.add({
          severity: 'error',
          summary: 'Error al subir el archivo',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
      emit('loading', false)
    }

    return {
      editableBrand,
      closeEditDialog,
      onSave,
      visible,
      isValidData,
      onUploaded,
      onUpload
    }
  }
})
